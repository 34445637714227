import React from "react";
import classnames from "classnames";
import "./style.less";

export type ContainerSize = "small" | "medium" | "large";

export interface Props {
  size?: ContainerSize;
  noMargin?: boolean;
  noPadding?: boolean;
  noPaddingTop?: boolean;
  extraPaddingTop?: boolean;
  extraMarginBottom?: boolean;
  whiteBg?: boolean;
  children?: React.ReactNode;
}

const Container: React.FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  children,
  className,
  size,
  noMargin,
  noPadding,
  noPaddingTop,
  extraPaddingTop,
  extraMarginBottom,
  whiteBg,
  ...rest
}) => (
  <div
    className={classnames("container", className, {
      [`container--${size}`]: size,
      "container--no-margin": noMargin,
      "container--no-padding": noPadding,
      "container--no-padding-top": noPaddingTop,
      "container--extra-padding-top": extraPaddingTop,
      "container--extra-margin-bottom": extraMarginBottom,
      "container--white-bg": whiteBg,
    })}
    {...rest}
  >
    {children}
  </div>
);

export default Container;
