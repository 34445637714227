import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "../header";
import Container from "../Container";
import "./style.less";

interface Props {
  children: React.ReactNode;
}

const Page: React.FC<Props> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header title={data.site.siteMetadata?.title || `Nyevo`} />
      <Container size="medium">
        <main>{children}</main>
      </Container>
    </>
  );
};

export default Page;
