import { Link } from "gatsby";
import React from "react";
import Container from "../Container";
import "./style.less";

interface Props {
  title?: string;
}

const Header: React.FC<Props> = ({ title = "" }) => (
  <header className="header">
    <Container size="medium">
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {title}
        </Link>
      </h1>
    </Container>
  </header>
);

export default Header;
